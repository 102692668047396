import Row from "react-bootstrap/Row";
import React, { useContext } from "react";
import productImage from "../../assets/images/pro1.jpg";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { OrderContext } from '../../context/orders';

const authUser = () => {
  const user = localStorage.getItem("isLoggedIn");
  return user;
};

function OrderDetailContent() {
  let isAuthUser = authUser();
  const [orderDetails, setOrderDetails] = React.useState([]);
  const [returnDetails, setReturnDetails] = React.useState([]);
  const navigate = useNavigate();
  let { orderId } = useParams();
  const [returnIds, setreturnIds] = React.useState([]);
  const {storeId, setStoreId} = useContext(OrderContext);
  const [reasons, setReasons] = React.useState({});
  const [productQtys, setProductQtys] = React.useState({});
  const [customReasons, setCustomReasons] = React.useState({});
  React.useEffect(() => {
    if (!storeId) {
      const storedStoreId = localStorage.getItem("storeid");
      if (storedStoreId) {
        setStoreId(storedStoreId);
      }
    }
  }, [storeId, setStoreId]);
  const notifyscucess = (errorMessage) => toast.success(errorMessage);
  const notifyerror = (successMessage) => toast.error(successMessage);

  const handleReturnButtonClick = async () => {
    const fulfillmentlineitem = document.querySelectorAll(
      'input[type="checkbox"][name="fulfillmentlineitem"]:checked'
    );
    if (!fulfillmentlineitem || fulfillmentlineitem.length === 0) {
      alert("No checkboxes are checked! Please check at least one.");
      return;
    }
    const returndata = Array.from(fulfillmentlineitem).map((checkbox) => {
      const fulfillmentlineitemid = checkbox.value;
      const productQtyInput = checkbox
        .closest("tr")
        .querySelector('input[name="productqty"]');
      //const productqty = productQtyInput ? productQtyInput.value : "";
      const productqty = productQtys[fulfillmentlineitemid] ? productQtys[fulfillmentlineitemid] : "";
      const returnreason = reasons[fulfillmentlineitemid] ? reasons[fulfillmentlineitemid] : "";
      const returnnote = customReasons[fulfillmentlineitemid] ? customReasons[fulfillmentlineitemid] : "";
      return { fulfillmentlineitemid, productqty, returnreason, returnnote };
    });

    // console.log(returndata);
    // return;

    axios({
      method: "post",
      url: `${process.env.REACT_APP_STORE_URL}/orders/order-detail/returncreate`,
      data: {
        orderId: orderId,
        fulfillmentlineitemids: returndata,
        warehouseId: storeId,
      },
    })
      .then((response) => {
        if (
          response.data.returnresult.returnRequest.userErrors &&
          response.data.returnresult.returnRequest.userErrors.length > 0
        ) {
          const errorMessage =
            response.data.returnresult.returnRequest.userErrors[0].message;
          notifyerror(errorMessage);
        } else {
          if (response.data.returnsdetails.length > 0) {
            let returnsdetails = response.data.returnsdetails[0];
            setreturnIds([
              ...returnIds,
              {
                [returnsdetails.returnfulfillmentLineItem]:
                  returnsdetails.productReturnId,
              },
            ]);
            handleCheckBox();
          }
          notifyscucess("Success: Return updated successfully");
        }
      })
      .catch((error) => {
        console.error("Error:", error);
        notifyerror("Error: Failed to update data. Please try again.");
      });
  };

  const handleApproveReturnButtonClick = async (fulfillmentLineItemId) => {

    const isConfirmed = window.confirm("Êtes-vous sur de vouloir accepter ce retour ?");
    if (!isConfirmed) {
      return; 
    }
    const fulfillmentlineitem = document.querySelectorAll(
      'input[type="checkbox"][name="fulfillmentlineitem"]:checked'
    );
    if (!fulfillmentlineitem || fulfillmentlineitem.length === 0) {
      alert("No checkboxes are checked! Please check at least one.");
      return;
    }
    const returnId = returnIds.find((returnId) =>
      returnId.hasOwnProperty(fulfillmentLineItemId)
    );
    if (!returnId) {
      alert(
        "No return ID found for the selected fulfillmentLineItemId:",
        fulfillmentLineItemId
      );
      return;
    }
    const selecteddata = Array.from(fulfillmentlineitem).map((checkbox) => {
      const fulfillmentlineitemid = checkbox.value;
      const productQtyInput = checkbox
        .closest("tr")
        .querySelector('input[name="returnproductqty"]');
      const returnproductqty = productQtyInput ? productQtyInput.value : "";
      const productqtyFromList = productQtys[fulfillmentlineitemid] ? productQtys[fulfillmentlineitemid] : "";
      const productqty = returnproductqty || productqtyFromList;
      const returnreason = customReasons[fulfillmentlineitemid] ? customReasons[fulfillmentlineitemid] : "";
      return { fulfillmentlineitemid, productqty,returnreason };
    });
    // console.log(selecteddata);
    // return;

    axios({
      method: "post",
      url: `${process.env.REACT_APP_STORE_URL}/orders/order-detail/returnaccept`,
      data: {
        orderId: orderId,
        fulfillmentlineitemids: selecteddata,
        returnId: returnId[fulfillmentLineItemId],
        warehouseId: storeId,
      },
    })
      .then((response) => {
        if (
          response.data.returnresult.returnApproveRequest.userErrors &&
          response.data.returnresult.returnApproveRequest.userErrors.length > 0
        ) {
          const errorMessage =
            response.data.returnresult.returnApproveRequest.userErrors[0].message;
          notifyerror(errorMessage);
        } else {
          // if (response.data.returnsdetails.length > 0) {
          //   let returnsdetails = response.data.returnsdetails[0];
          //   console.log(returnsdetails);
          //   setreturnIds([
          //     ...returnIds,
          //     {
          //       [returnsdetails.returnfulfillmentLineItem]:
          //         returnsdetails.productReturnId,
          //     },
          //   ]);
          //   handleCheckBox();
          // }
          const newReturnIds = returnIds.filter((returnIds) => {
            if (!(fulfillmentLineItemId in returnIds)) {
              return returnIds;
            }
          });
          setreturnIds((prevReturnIds) => [...newReturnIds]);
          handleCheckBox();
          notifyscucess("Success: Return updated successfully");
        }
      })
      .catch((error) => {
        console.error("Error:", error);
        notifyerror("Error: Failed to update data. Please try again.");
      });
  };

  const handleCancelButtonClick = async (fulfillmentLineItemId) => {

    const isConfirmed = window.confirm("Êtes-vous sûr de vouloir refuser ce retour?");
    if (!isConfirmed) {
      return; 
    }
    const fulfillmentlineitem = document.querySelectorAll(
      'input[type="checkbox"][name="fulfillmentlineitem"]:checked'
    );
    if (!fulfillmentlineitem || fulfillmentlineitem.length === 0) {
      alert("No checkboxes are checked! Please check at least one.");
      return;
    }
    const returnId = returnIds.find((returnId) =>
      returnId.hasOwnProperty(fulfillmentLineItemId)
    );
    if (!returnId) {
      alert(
        "No return ID found for the selected fulfillmentLineItemId:",
        fulfillmentLineItemId
      );
      return;
    }
    const selecteddata = Array.from(fulfillmentlineitem).map((checkbox) => {
      const fulfillmentlineitemid = checkbox.value;
      const productQtyInput = checkbox
        .closest("tr")
        .querySelector('input[name="productqty"]');
      const returnproductqty = productQtyInput ? productQtyInput.value : "";
      const productqtyFromList = productQtys[fulfillmentlineitemid] ? productQtys[fulfillmentlineitemid] : "";
      const productqty = returnproductqty || productqtyFromList;
      const returnreason = customReasons[fulfillmentlineitemid] ? customReasons[fulfillmentlineitemid] : "";
      return { fulfillmentlineitemid, productqty, returnreason };
    });

    // console.log(selecteddata);
    // return;

    axios({
      method: "post",
      url: `${process.env.REACT_APP_STORE_URL}/orders/order-detail/returncancel`,
      data: {
        orderId: orderId,
        fulfillmentlineitemids: selecteddata,
        returnId: returnId[fulfillmentLineItemId],
        warehouseId: storeId,
      },
    })
      .then((response) => {
        console.error(response);
        if (
          response.data.returnresult.returnDeclineRequest.userErrors &&
          response.data.returnresult.returnDeclineRequest.userErrors.length > 0
        ) {
          const errorMessage =
            response.data.returnresult.returnDeclineRequest.userErrors[0].message;
          notifyerror(errorMessage);
        } else {
          const newReturnIds = returnIds.filter((returnIds) => {
            if (!(fulfillmentLineItemId in returnIds)) {
              return returnIds;
            }
          });
          setreturnIds((prevReturnIds) => [...newReturnIds]);
          handleCheckBox();
          notifyscucess("Success: Return updated successfully");
        }
      })
      .catch((error) => {
        console.error("Error:", error);
        notifyerror("Error: Failed to update data. Please try again.");
      });
  };

  const fetchorderData = async () => {
    const response = await axios.get(
      `${process.env.REACT_APP_STORE_URL}/orders/order-detail/${orderId}`
    );
    if (response.data.orders) {
      setOrderDetails(response.data.orders);
    }
    // if (response.data.orders) {
    //   setReturnDetails(response.data.orders);
    // }
    console.log(response.data.orders);
    if (response.data.returnsdetails.length > 0) {
      const returnsdetails = response.data.returnsdetails.map(
        (returnsdetails) => ({
          [returnsdetails.returnfulfillmentLineItem]:
            returnsdetails.productReturnId,
        })
      );
      setreturnIds((prevReturnIds) => [...prevReturnIds, ...returnsdetails]);
      handleCheckBox();
      //setreturnIds([returnsdetails]);
    }
  };

  React.useEffect(() => {
    
  }, [returnIds]);

  React.useEffect(() => {
    if (isAuthUser) {
      navigate(`/order-detail/${orderId}`);
    } else {
      navigate("/login");
    }
    fetchorderData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthUser, orderId]);

  const checkReturn = (fulfillmentLineItemId) => {
    return returnIds.some((returnId) =>
      returnId.hasOwnProperty(fulfillmentLineItemId)
    );
  };

  const handleCheckBox = () => {
    let orderDetailsCheckBox = document.querySelectorAll(".order-checkbox");
    orderDetailsCheckBox.forEach((elem) => {
      elem.checked = false;
    });
  };

  const handleReasonChange = (event, fulfillmentLineItemId) => {
    const { value } = event.target;
    setReasons((prevReasons) => ({
      ...prevReasons,
      [fulfillmentLineItemId]: value,
    }));
    setCustomReasons(prevState => ({
      ...prevState,
      [fulfillmentLineItemId]: value,
    }));
  };
  const handleQuantityChange = (event, fulfillmentLineItemId) => {
    const { value } = event.target;
    console.log("product qry :");
    console.log(value);
    setProductQtys((prevquantity) => ({
      ...prevquantity,
      [fulfillmentLineItemId]: value,
    }));
  };
  return (
    <Row className="order-detail-content ">
      <ToastContainer
        position="top-center"
        autoClose={3000}
        hideProgressBar
        newestOnTop
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover={false}
        theme="dark"
      />
      {orderDetails.map((order) => (
        <>
          <div className="table-responsive p-0">
            <table className="ordered-by ">
              <tbody className="">
                <tr key={order.id_order}>
                  <td className="text-center p-3">
                    <label> ID commande :</label> {order.id_order}
                  </td>
                  <td className="text-center p-3">
                    <label> Nom et prénom : </label> {order.customername}
                  </td>
                  <td className=" text-center p-3">
                    <label> Référence : </label> {order.reference}
                  </td>
                  <td className="text-center p-3">
                    <label> Type de paiement : </label> {order.payment}
                  </td>
                  <td className="text-center  p-3">
                    <label> Date :</label> {order.added_date}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className="table-responsive p-0">
            <table className="">
              <thead>
                <tr className="align-items-center">
                  <th className="p-2"></th>
                  <th className="p-2">ID Produit</th>
                  <th className="p-2 min-width-25">Nom du Produit</th>
                  <th className="p-2 min-width-25">Sku</th>
                  <th className="p-2">Quantité</th>
                  <th className="p-2">Retour Quantité</th>
                  <th className="p-2">Total</th>
                  <th className="p-2 reason">Reason</th>
                  <th className="p-2 text-center">Action</th>
                </tr>
              </thead>
              <tbody className="">
              {order.orderProducts.map((product, index) => {
                // const returnProduct = order.returnRequestedProducts[product.fulfillmentLineItemId]?.find(
                //   (rp) => rp.returnquantity && rp.returnquantity === product.productQty
                // );

                const returns = order.returnRequestedProducts[product.fulfillmentLineItemId] || [];

                const returnProduct = returns.find(rp => rp.returnstatus === "OPEN") || returns[0];

                const showInputField = !returnProduct || returnProduct.returnquantity !== product.productQty;

                //if (returnProduct && returnProduct.returnquantity && returnProduct.returnquantity === product.productQty) {
                  //if (returnProduct && returnProduct.returnquantity && product.returnstatus === "OPEN") {
                    return (
                      <tr
                        key={index}
                        className="align-items-center"
                      >
                        <td className="p-2 justify-content-around d-flex align-items-center ">
                          <input
                            type="checkbox"
                            name="fulfillmentlineitem"
                            className="order-checkbox"
                            value={product.fulfillmentLineItemId}
                          />
                          <img
                            className="img-fluid p-0 m-10"
                            src={product.productImg}
                            alt="productImage"
                            width="100%"
                            height="auto"
                            style={{ maxWidth: "60px" }}
                          />
                        </td>
                        <td className="p-2">{product.productId}</td>
                        <td className="carrier min-width-25 p-2">
                          {product.productName}
                        </td>
                        <td className="carrier min-width-25 p-2">
                          {product.productSku}
                        </td>
                        <td className="p-2">
                        {product.productQty}
                        </td>
                        <td className="p-2">
                          {showInputField ? (
                            <input
                              type="text"
                              name="productqty"
                              className="product-qty"
                              onChange={(e) => handleQuantityChange(e, product.fulfillmentLineItemId)}
                              value={productQtys[product.fulfillmentLineItemId] || ""}
                            />
                          ) : (
                            <span>
                              {returnProduct.returnquantity}
                            </span>
                          )}
                        </td>
                        <td className="p-2">{product.productPrice} €</td>
                        <td className="date p-2 reason">
                            {showInputField ? (
                              <select
                                name="reason"
                                className="return-reason"
                                onChange={(e) => handleReasonChange(e, product.fulfillmentLineItemId)}
                                value={reasons[product.fulfillmentLineItemId] || ""}
                              >
                                <option value="">Sélectionner une raison</option>
                                <option value="Inconnue">Inconnue</option>
                                <option value="Taille trop petite">Taille trop petite</option>
                                <option value="Taille trop grande">Taille trop grande</option>
                                <option value="Le client a changer d’avis">Le client a changer d’avis</option>
                                <option value="Le produit de ne correspond pas à la description">Le produit de ne correspond pas à la description</option>
                                <option value="Le produit reçu n’est pas le bon">Le produit reçu n’est pas le bon</option>
                                <option value="Produit abîmé ou défectueux">Produit abîmé ou défectueux</option>
                                <option value="Style">Style</option>
                                <option value="Couleur">Couleur</option>
                              </select>
                            ) : (
                              <span>
                                {product.returnreason}
                              </span>
                            )}
                        </td>
                        <td className="date p-2 text-center ">
                            {showInputField ? (
                              <span
                                className="btn rounded-pill w-auto px-3 background-none border-blue mt-2"
                                onClick={handleReturnButtonClick}
                              >
                                Traiter le retour
                              </span>
                            ) : (
                              <>
                                <span
                                  className="btn rounded-pill w-auto px-3 background-none border-blue mt-2"
                                  onClick={() => handleApproveReturnButtonClick(product.fulfillmentLineItemId)}
                                >
                                  Accepter
                                </span>
                                <span
                                  className="btn rounded-pill w-auto px-3 background-none border-blue mx-1 mt-2"
                                  onClick={() => handleCancelButtonClick(product.fulfillmentLineItemId)}
                                >
                                  Refused
                                </span>
                              </>
                            )}
                        </td>
                      </tr>
                      );
                    //}
                  }
                  )}
                  {Object.keys(order.returnRequestedProducts).map((fulfillmentLineItemId) => {
                    const product = order.orderProducts.find(
                      op => op.fulfillmentLineItemId === fulfillmentLineItemId
                    );

                    return order.returnRequestedProducts[fulfillmentLineItemId].map((returnProduct, index) => {
                      // Only render the row if returnquantity is not equal to productQty
                     // if (returnProduct.returnquantity && returnProduct.returnquantity !== product.productQty) {
                        if (returnProduct.returnstatus && (returnProduct.returnstatus == "REQUESTED" || returnProduct.returnstatus == "DECLINED")) {
                        return (
                          <tr key={index} className="align-items-center">
                            <td className="p-2 justify-content-around d-flex align-items-center">
                              <input
                                type="checkbox"
                                name="fulfillmentlineitem"
                                className="order-checkbox"
                                value={product.fulfillmentLineItemId}
                              />
                              <img
                                className="img-fluid p-0 m-10"
                                src={product.productImg}
                                alt="productImage"
                                width="100%"
                                height="auto"
                                style={{ maxWidth: "60px" }}
                              />
                            </td>
                            <td>{product.productId}</td>
                            <td>{product.productName}</td>
                            <td>{product.productSku}</td>
                            <td>{product.productQty}</td>
                            <td className="p-3">
                              <input
                                type="hidden"
                                name="returnproductqty"
                                value={returnProduct.returnquantity}
                              />
                              {returnProduct.returnquantity}
                            </td>
                            <td>{returnProduct.returnreason}</td>
                            <td className="date p-2 reason">
                              {(returnProduct.returnstatus !== "OPEN") ? (
                                  (!checkReturn(fulfillmentLineItemId) && returnProduct.returnstatus !== "REQUESTED") ? (
                                    <select
                                      name="reason"
                                      className="return-reason"
                                      onChange={(e) => handleReasonChange(e, fulfillmentLineItemId)}
                                      value={reasons[fulfillmentLineItemId] || ""}
                                    >
                                      <option value="">Sélectionner une raison</option>
                                      <option value="Inconnue">Inconnue</option>
                                      <option value="Taille trop petite">Taille trop petite</option>
                                      <option value="Taille trop grande">Taille trop grande</option>
                                      <option value="Le client a changer d’avis">Le client a changer d’avis</option>
                                      <option value="Le produit de ne correspond pas à la description">Le produit de ne correspond pas à la description</option>
                                      <option value="Le produit reçu n’est pas le bon">Le produit reçu n’est pas le bon</option>
                                      <option value="Produit abîmé ou défectueux">Produit abîmé ou défectueux</option>
                                      <option value="Style">Style</option>
                                      <option value="Couleur">Couleur</option>
                                    </select>
                                  ) : (
                                    <span>
                                        {returnProduct.returnreason}
                                    </span>
                                  )
                              ) : (
                                <span>
                                  {returnProduct.returnreason}
                                </span>
                              )}
                            </td>
                            <td className="date p-2 text-center ">
                              {(returnProduct.returnstatus !== "OPEN") ? (
                                (!checkReturn(fulfillmentLineItemId) && returnProduct.returnstatus !== "REQUESTED") ? (
                                  <span
                                    className="btn rounded-pill w-auto px-3 background-none border-blue mt-2"
                                    onClick={handleReturnButtonClick}
                                  >
                                    Traiter le retour
                                  </span>
                                ) : (
                                  <>
                                    <span
                                      className="btn rounded-pill w-auto px-3 background-none border-blue mt-2"
                                      onClick={() => handleApproveReturnButtonClick(fulfillmentLineItemId)}
                                    >
                                      Accepter
                                    </span>
                                    <span
                                      className="btn rounded-pill w-auto px-3 background-none border-blue mx-1 mt-2"
                                      onClick={() => handleCancelButtonClick(fulfillmentLineItemId)}
                                    >
                                      Refused
                                    </span>
                                  </>
                                )
                              ) : (
                                returnProduct.returnstatus === "DECLINED" ? "REFUSÉ" : "TERMINÉ"
                              )}
                            </td>
                          </tr>
                        );
                      }
                      // Return null if returnquantity equals productQty to prevent rendering
                      return null;
                    });
                  })}
              </tbody>
            </table>
          </div>
        </>
      ))}
      <button
        type="button"
        className="btn btn-primary m-4 rounded-pill w-auto p-20 border-coupon blue-background"
        onClick={() => window.history.back()}
      >
        Retour
      </button>
    </Row>
  );
}

export default OrderDetailContent;
